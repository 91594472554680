import * as Sentry from '@sentry/react';

if (window.SENTRY_DSN) {
  Sentry.init({
    dsn: window.SENTRY_DSN,
    environment: window.ENVIRONMENT,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    maxBreadcrumbs: 20,
    release: process.env.REACT_APP_SENTRY_RELEASE, // expected to be generated during build
    sampleRate: window.ENVIRONMENT === 'PRD' ? 1 : 0.1,
  });
}

export default Sentry;
