import bidding from './bidding';
import common from './common';
import compose from './compose';
import documentCollection from './documentCollection';
import firstMile from './firstMile';
import message from './message';
import notification from './notification';
import pbiPartnership from './pbiPartnership';

export default {
  bidding,
  common,
  compose,
  document_collection: documentCollection,
  first_mile: firstMile,
  message,
  notification,
  pbiPartnership,
};
