/* eslint-disable import/first */
require('dotenv').config();

import React, { useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { TmsUIProvider } from '@kargotech/tms-ui';
import { AuthProvider } from '@kargotech/tms-core/auth';
import FlagProvider from '@unleash/proxy-client-react';

import {
  APP_CONTEXT_STORAGE_KEY,
  BANNER_STORAGE_KEY,
  COACH_MARK_STORAGE_KEY,
  FREE_TRIAL_LOGIN_FLAG,
} from '~/Configurations/constants';
import theme from '~/Configurations/theme';
import Routes from '~/Configurations/Routes';
import UNLEASH_CONFIGS from '~/Configurations/unleash';
import AppProvider from '~/Contexts/AppProvider';
import ApolloClientProvider from '~/Contexts/ApolloClientProvider';
import ProfileProvider from '~/Contexts/ProfileProvider';
import NavigationProvider from '~/Contexts/NavigationProvider';
import NftModulesProvider from '~/Contexts/NftModulesProvider';
import { APOLLO_CLIENTS } from '~/Services/apollo';

export function UIWrapper({ children }) {
  const { i18n } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <TmsUIProvider i18n={i18n}>
        {children}
      </TmsUIProvider>
    </ThemeProvider>
  );
}

export function AppWrapper({ children }) {
  const handleLogout = useCallback(() => {
    Object.keys(APOLLO_CLIENTS).forEach(apolloClient => {
      const cache = get(APOLLO_CLIENTS, `${apolloClient}.cache`);
      if (cache) {
        cache.reset();
      }
    });
  }, []);

  return (
    <UIWrapper>
      <AppProvider>
        <AuthProvider
          apiClient={APOLLO_CLIENTS.PROFILE}
          onLogoutSuccess={handleLogout}
          preservedLocalStorageKeys={[
            APP_CONTEXT_STORAGE_KEY,
            BANNER_STORAGE_KEY,
            ...Object.values(COACH_MARK_STORAGE_KEY),
            FREE_TRIAL_LOGIN_FLAG,
          ]}
        >
          <ApolloClientProvider>
            <FlagProvider config={UNLEASH_CONFIGS}>
              <ProfileProvider>
                <NavigationProvider>
                  <NftModulesProvider>
                    {children}
                  </NftModulesProvider>
                </NavigationProvider>
              </ProfileProvider>
            </FlagProvider>
          </ApolloClientProvider>
        </AuthProvider>
      </AppProvider>
    </UIWrapper>
  );
}

export default function App() {
  return (
    <AppWrapper>
      <Router>
        <Routes />
      </Router>
    </AppWrapper>
  );
}
