export const DATETIME_FORMAT = 'DD MMM YYYY HH:mm';

export const DELIVERY_MILESTONE_TYPES = {
  MID_MILE: 'MID_MILE',
  FIRST_MILE: 'FIRST_MILE',
};

export const DELIVERY_ORDER_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  LICENSE_PLATE: 'LICENSE_PLATE',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
};

export default {};
